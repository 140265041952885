<template>
    <div class="main">
          <div class="title"> <img style="box-shadow:none;width:30px;" src="../../../assets/imgs/选刊投稿/小标题指示.png"  alt=""/>【查看全部期刊】</div>
          <div class="center">
                <img src="../../../assets/imgs/选刊投稿/查看全部期刊.png" alt="">
                <div>
                    <img src="../../../assets/imgs/选刊投稿/查看全部外文期刊.png" alt="">
                    <p>查看全部外文期刊</p>
                </div>
                <div>
                    <img src="../../../assets/imgs/选刊投稿/查看全部国内核心期刊.png" alt="">
                    <p>查看全部国内核心期刊</p>
                </div>
          </div>
    </div>
  </template>
  
  <script>
  export default {
  
  }
  </script>
  
  <style lang="less" scoped>
  .main{
      width: 70%;
      margin:  0 auto;
  }
  .title{
      font-size: 18px;
      color: #3F81C1 ;
      font-weight: 600;
      display: flex;
      align-items: center;
  }
  .center{
      line-height: 1.5;
      width: 90%;
      padding: 40px 0;
      margin: 20px auto;
      display: flex;
      flex-wrap:wrap ;
      justify-content: space-between;
      align-items: center;
  }
  .center>div{
    width: 48%; 
    margin-top: 20px;  
    margin-bottom: 40px;
  }
  .center>img{
    width: 100%;
  }
  .center>div>img{
    width: 100%;

  }
  .center>div>p{
    margin-top: 10px;
    text-align: center;
    text-indent: 2rem;
  }
  p{
    font-size: 16px;
}
img{
    box-shadow: rgba(150, 150, 150, 1) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
}
  </style>