<template>
  <div class="main">
        <div class="title"> <img src="../../../assets/imgs/选刊投稿/小标题指示.png" style="width:30px;box-shadow:none;" alt=""/>【系统介绍】</div>
        <div class="center">
            <p>选刊大数据分析系统是面向国内科研工作者的专业期刊选择与投稿辅助系统，是原版人工选刊大数据分析服务的全新升级版本，能够为广大作者提供客观、真实的刊物信息，为发文工作提供有效的参考依据。平台完整收录了国内外重点评价来源与核心刊源的学术期刊与专业期刊，全面涵盖SCIE、SSCI、A&HCI、ESCI、EI、北大核心、CSSCI、CSTPCD、CSCD九大评价源，支持提供两万多种外文期刊和三千多种国内核心期刊的出版周期、收录来源、评价指数、论文发表周期与审稿速度等在内的重要信息，并能够根据发文作者自身实际需求实现刊物匹配与遴选。</p>
        </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.main{
    width: 70%;
    margin:  0 auto;
}
.title{
    font-size: 18px;
    color: #3F81C1 ;
    font-weight: 600;
    display: flex;
    align-items: center;
}
.center{
    line-height: 1.5;
    width: 90%;
    padding: 40px 0;
    text-indent: 2rem;
    margin: 20px auto;
}
p{
    font-size: 16px;
}
img{
    box-shadow: rgba(150, 150, 150, 1) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
}
</style>