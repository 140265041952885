<template>
  <div class="main">
        <div class="title"> <img src="../../../assets/imgs/选刊投稿/小标题指示.png" style="width:30px;box-shadow:none;" alt=""/>【期刊搜索】</div>
        <div class="center">
              <img src="../../../assets/imgs/选刊投稿/期刊搜索.png" alt="">
              <p>外文期刊与国内核心期刊都支持输入中英文检索</p>
        </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.main{
    width: 70%;
    margin:  0 auto;
}
.title{
    font-size: 18px;
    color: #3F81C1 ;
    font-weight: 600;
    display: flex;
    align-items: center;
}
.center{
    line-height: 1.5;
    width: 90%;
    padding: 40px 0;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.center>img{
  width: 60%;
}
p{
    font-size: 16px;
    margin-top: 15px;
}
img{
  box-shadow: rgba(150, 150, 150, 1) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
}
</style>