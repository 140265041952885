<template>
    <div class="main">
          <div class="title"> <img src="../../../assets/imgs/选刊投稿/小标题指示.png" style="width:30px;box-shadow:none;" alt=""/>【查看期刊详情】</div>
          <div class="center">
                <div>
                    <p>外文期刊详情页展示了期刊的SJR、H指数、SNIP等指数，分区、学科、出版周期、审稿速度、官网链接、投稿链接等信息，我们还直观呈现了近三年、五年、十年的中国作者发文量、发文占比与期刊总发文量。</p>
                    <img src="../../../assets/imgs/选刊投稿/查看外文期刊详情.png" alt="">
                </div>
                <div>
                    <p>国内核心期刊详情页展示了期刊的影响因子、发文量、基金论文量、下载次数、被引次数等指标，出版周期、主编、电话、主办单位、电子邮箱等信息，我们还直观呈现了期刊近十年文献的学科分布、关键词分布。</p>
                    <img src="../../../assets/imgs/选刊投稿/查看国内核心期刊详情.png" alt="">
                </div>
          </div>
    </div>
  </template>
  
  <script>
  export default {
  
  }
  </script>
  
  <style lang="less" scoped>
  .main{
      width: 70%;
      margin:  0 auto;
  }
  .title{
      font-size: 18px;
      color: #3F81C1 ;
      font-weight: 600;
      display: flex;
      align-items: center;
  }
  .center{
      line-height: 1.5;
      width: 90%;
      padding: 40px 0;
      margin: 20px auto;
      display: flex;
      flex-direction: column;
      align-items: center;
  }
  .center>div{
    width: 80%; 
    margin-bottom: 40px;
  }
  .center>div>img{
    width: 100%;
  }
  .center>div>p{
    text-indent: 2rem;
  }
  p{
    font-size: 16px;
    margin-bottom: 15px;
}
img{
    box-shadow: rgba(150, 150, 150, 1) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
}
  </style>