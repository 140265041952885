<template>
    <div class="main">
          <div class="title"> <img src="../../../assets/imgs/选刊投稿/小标题指示.png" style="width:30px;box-shadow:none;" alt=""/>【期刊匹配】</div>
          <div class="center">
              <p>作者可输入论文的标题、摘要、关键词信息来匹配发表过相关主题论文的期刊，支持检索国内外期刊。匹配国内核心期刊只需输入标题与关键词。</p>
          </div>
          <div class="center_img">
            <div><p>· 匹配外文期刊</p><img src="../../../assets/imgs/选刊投稿/期刊匹配：外文期刊.png" alt=""></div>
            <div><p>· 匹配国内核心期刊</p><img src="../../../assets/imgs/选刊投稿/期刊匹配：国内核心期刊.png" alt=""></div>
          </div>
    </div>
  </template>
  
  <script>
  export default {
  
  }
  </script>
  
  <style lang="less" scoped>
  .main{
      width: 70%;
      margin:  0 auto;
  }
  .title{
      font-size: 18px;
      color: #3F81C1 ;
      font-weight: 600;
      display: flex;
      align-items: center;
  }
  .center{
      line-height: 1.5;
      width: 90%;
      padding: 40px 0;
      margin: 20px auto;
  }
  .center_img{
    display: flex;
    justify-content: space-around;
  }
  .center_img>div{
    width: 48%;
    margin-bottom:50px;
  }
  .center_img>div>p{
    font-weight: 600;
    margin-bottom: 15px;
  }
  .center_img>div>img{
    width: 100%;
  }
  p{
    font-size: 16px;
}
img{
    box-shadow: rgba(150, 150, 150, 1) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
}
  </style>