<template>
    <div class="main">
          <div class="title"> <img src="../../../assets/imgs/选刊投稿/小标题指示.png" style="width:30px;box-shadow:none;" alt=""/>【检索结果】</div>
          <div class="center">
                <div>
                    <img src="../../../assets/imgs/选刊投稿/检索结果.png" alt="">
                </div>
                <div>
                    <p>检索结果以excel形式批量导出</p>
                    <img src="../../../assets/imgs/选刊投稿/导出表格.png" alt="">
                </div>
          </div>
    </div>
  </template>
  
  <script>
  export default {
  
  }
  </script>
  
  <style lang="less" scoped>
  .main{
      width: 70%;
      margin:  0 auto;
  }
  .title{
      font-size: 18px;
      
      color: #3F81C1 ;
      font-weight: 600;
      display: flex;
      align-items: center;
  }
  .center{
      line-height: 1.5;
      width: 90%;
      padding: 40px 0;
      margin: 20px auto;
      display: flex;
      flex-direction: column;
      align-items: center;
  }
  .center>div{
    width: 80%; 
    margin-bottom: 25px;
  }
  .center>div>img{
    width: 100%;
  }
  p{
    font-size: 16px;
    margin-bottom: 15px;
}
img{
    box-shadow: rgba(150, 150, 150, 1) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
}
  </style>