<template>
  <div class="search_box" v-bind:style="{ minHeight: Height + 'px' }">
    <!-- 文献查找 -->
    <el-container>
      <el-aside width="250px">
        <el-menu
          :default-active="activeIndex"
          class="el-menu-vertical-demo"
          @select="handleSelect"
          style="position: fixed;width: 250px"
        >
          <el-menu-item index="1" style="margin-top: 20px">
            <div class="aside_item">
              <span slot="title">系统介绍</span>
            </div>
          </el-menu-item>
          <el-menu-item index="2">
            <div class="aside_item">
                <span slot="title">
                    期刊匹配
                </span>
            </div>
          </el-menu-item>
          <el-menu-item index="3">
            <div class="aside_item">
              <span slot="title"> 期刊搜索</span>
            </div>
          </el-menu-item>
          <el-menu-item index="4">
            <div class="aside_item">
              <span slot="title"> 检索结果</span>
            </div>
          </el-menu-item>
          <el-menu-item index="5">
            <div class="aside_item">
              <span slot="title"> 查看期刊详情</span>
            </div>
          </el-menu-item>
          <el-menu-item index="6">
            <div class="aside_item">
              <span slot="title"> 查看全部期刊</span>
            </div>
          </el-menu-item>
          <div class="system" @click="toSystem">
            <img src="../../assets/imgs/选刊投稿/进入系统指示.png" alt=""><span>进入系统</span>
          </div>
        </el-menu>
      </el-aside>
      <el-main v-bind:style="{ minHeight: Height + 'px' }">
        
        <div class="main_center" v-bind:style="{ minHeight: Height + 'px' }">
            <div class="main_logo">
                <img src="../../assets/imgs/选刊投稿/选刊logo.png" alt="">
                <span>选刊大数据分析系统</span>
            </div>
            <view1 id="view1"></view1>
            <view2 id="view2"></view2>
            <view3 id="view3"></view3>
            <view4 id="view4"></view4>
            <view5 id="view5"></view5>
            <view6 id="view6"></view6> 
        </div>
        <!-- <Management v-if="keys == '2'" @getTreeData="testData"></Management>
        <TOOL v-if="keys == '3'"></TOOL> -->
      </el-main>
    </el-container>
  </div>
</template>
 
<script>
import view1 from './jguide_component/view1.vue'
import view2 from './jguide_component/view2.vue'
import view3 from './jguide_component/view3.vue'
import view4 from './jguide_component/view4.vue'
import view5 from './jguide_component/view5.vue'
import view6 from './jguide_component/view6.vue'
export default {
    components:{
        view1,
        view2,
        view3,
        view4,
        view5,
        view6,
    },
    data(){
        return{
            Height:0,
            keys:'1',
            activeIndex:'1',
            scroll:''
        }
    },
    created(){
        this.Height = document.documentElement.clientHeight - 140; //监听浏览器窗口变化
        window.onresize = () => {
            this.Height = document.documentElement.clientHeight - 140;
        };
    },
    mounted(){
        window.addEventListener('scroll',this.handleScroll)
    },
    methods:{
        toSystem(){
            if(window.localStorage.getItem('selected_journals') == true || window.localStorage.getItem('selected_journals') == 'true'){
                window.open('http://jguide.newacademic.net/?token='+ window.localStorage.getItem('token'),'_blank');
            }else{
                this.$message({
                message: "暂未开通，敬请期待~",
                type: "info",
                })
            }
        },
        handleSelect(key) {
            var top = document.getElementById('view'+key).offsetTop
            console.log(document.getElementById('view'+key).offsetTop -100);
            document.documentElement.scrollTop = top-100
            this.activeIndex = key;
        },
        handleScroll(){
            this.scroll = Math.ceil(document.documentElement.scrollTop)
            console.log(this.scroll);
            if(this.scroll >= 0 && this.scroll < document.getElementById('view2').offsetTop-100 ){
                this.activeIndex = '1'
            }else if(this.scroll >= document.getElementById('view2').offsetTop-100 && this.scroll < document.getElementById('view3').offsetTop-100 ){
                this.activeIndex = '2'
            }else if(this.scroll >= document.getElementById('view3').offsetTop-100 && this.scroll < document.getElementById('view4').offsetTop-100 ){
                console.log(document.getElementById('view3').offsetTop-100 ); 
                this.activeIndex = '3'
            }else if(this.scroll >= document.getElementById('view4').offsetTop-100 && this.scroll < document.getElementById('view5').offsetTop-100 ){
                this.activeIndex = '4'
            }else if(this.scroll >= document.getElementById('view5').offsetTop-100 && this.scroll < document.getElementById('view6').offsetTop-100 ){
                this.activeIndex = '5'
            }else{
                this.activeIndex = '6' 
            }
        }
    }
}
</script>

<style scoped lang="less">

.main_center{
    background: #fff;
    width: 100%;
}
.search_box {
  // height: 100vh;
  padding-top: 75px;
  .el-aside {
    background-color: #fff;
    color: #333;
    text-align: center;
    line-height: 200px;
  }

  .el-main {
    min-width: 1130px;
    height: 100%;
    background-color: #e9eef3;
    color: #333;
    // text-align: center;
    // line-height: 160px;
  }

  body > .el-container {
    margin-bottom: 40px;
  }

  .el-container:nth-child(5) .el-aside,
  .el-container:nth-child(6) .el-aside {
    line-height: 260px;
  }

  .el-container:nth-child(7) .el-aside {
    line-height: 320px;
  }
  .el-menu {
    background-color: #fff;
  }
}
.is-active div {
  background-color: #B14A75;
  border-radius: 5px;
  span {
    color: #fff;
  }
  i {
    color: #fff;
  }
}
.el-menu-item {
  padding: 5px 50px !important;
  background-color: #fff;
}
.aside_item {
  width: 100%;
  height: 50px;
  line-height: 50px;
  span {
    color: #b1aec8;
    font-size: 15px;
  }
}
.item {
  line-height: 30px;
  /deep/ .el-badge__content.is-fixed {
    position: absolute;
    top: 0px;
    right: 13px;
    transform: translateY(-50%) translateX(100%);
  }
}
.popContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.5);
  img {
    z-index: 99;
  }
  .el-button {
    z-index: 100;
    position: absolute;
    top: 200px;
    right: 300px;
    background: rgb(79, 176, 255);
    border: none;
  }
}
.main_logo{
    padding: 60px 0;
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img{
        width: 70px;
    }
    span{
        margin-left: 10px;
        font-size: 35px;
        color: #02A7F0;
        font-weight: 600;
        letter-spacing:5px; 
    }
}
.system{
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 70px;
    img{
        width:50px;
    }
    span{
        font-size: 20px;
        color:#B14A75;
        font-weight: 600;
        margin-left: 5px;
    }
}
</style>